import request from "./request"; //引入封装好的axios

const api ={
    // 把所有接口都在这里定义出来
    getConfig:'api/index/getConfig', //公共配置
    apk:'api/index/getWebpageAppList',//apk
    apks:'api/index/getAppList'//往期apk
}

//公共配置
export function getConfig(){
    return request({
        url:api.getConfig,
        method:"get",
        params:{type:2},//版本1 = 1 ； 版本2 = 2
    })
}
export function apk(e){
    return request({
        url:api.apk,
        method:"get",
        params:e,
    })
}
export function apks(e){
    return request({
        url:api.apks,
        method:"get",
        params:e,
    })
}






// export function housegeng(parameter){
//     return request({
//         url:api.housegeng,
//         method:"POST",
//         data:parameter
//     })
// }
