<template>
  <div>
    <!-- 内容 -->
    <div class="box">
      <!-- 头部 -->
      <div class="top">
        <div class="icon">
          <div class="left">
            <!-- <img :src="apkimg.value" alt=""> -->
            <img src="../logo/dianbao.png" alt="">
          </div>
          <div class="right">
            <div class="name">{{ apkname.value }}</div>
            <div class="version">{{ apkversion.value }}</div>
            <div class="miaoshu">{{ apkmiaoshu.value }}</div>
          </div>
        </div>
        <div class="btns">
          <div class="one ss">
            <div class="top">安卓</div>
            <div class="bottom">平台</div>
          </div>
          <div class="leftxian"></div>
          <div class="two ss">
            <div class="top">{{ apksize.value }}</div>
            <div class="bottom">{{ apksize.title }}</div>
          </div>
          <div class="leftxian"></div>
          <div class="three" @click="dian(apkurl.value)">点击下载</div>
        </div>
      </div>

      <div class="xian shang"></div>

      <!-- 福利app推荐 -->
      <div class="zhong" v-if="fuliapp.length > 0">
        <div class="top">福利APP推荐</div>
        <div class="bottom">
          <div class="haha" :class="[fuliapp.length > 4 ? 'sikuan' : 'sikuans']">
            <div v-for="item in fuliapp" :key="'b' + item" class="item" @click="dians(item)">
              <img :src="item.image" alt="">
              <div class="txt">{{ item.name }}</div>
            </div>
          </div>

        </div>
      </div>



      <div class="xian xia"></div>
      <div class="bottom">
        <div class="name">收不到验证码可以下载旧版本</div>

        <div class="lists " :class="[obb ? 'gaos' : 'gao']">
          <div class="item" v-for="item in arrapk" :key="'sss' + item.id" @click="dian(item.url_file)">
            <div class="left">
              <div class="img">
                <img :src="item.image" alt="">
              </div>
              <div class="txt">
                <div class="names">{{ item.name }}</div>
                <div class="data">{{ item.f_date }}</div>
              </div>
            </div>
            <div class="right">
              <div class="txt">{{ item.version }}</div>
            </div>
          </div>

        </div>
      </div>
      <div class="chakan" v-if="!obb && arrapk.length > 4" @click="obb = !obb">查看更多</div>
    </div>


    <!-- 遮罩层 -->
    <div class="zhe" v-if="showzhe">
      <img src="../logo/jiazai.png" alt="" />
    </div>

    <!-- 提示框 -->
    <div class="tishi" :class="[showtishi ? 'tishis' : '']" v-html="tishi"></div>
  </div>
</template>
<script>
// import Notice from "../components/Notice";
// import ClipboardJS from "clipboard";

/* 
  getConfig   公共配置
  apk     apk
  apks    往期apk
*/
import {
  getConfig,
  apk,
  apks
} from "../units/api.js";
export default {
  data() {
    return {
      showtishi: false, //提示框判断
      tishi: "", //提示框信息
      showzhe: false, //遮罩层

      apkimg: '',//apk图片
      apkname: '',//apk名字
      apkurl: '',//应用下载链接
      apksize: '',//apk大小
      apkversion: '',//apk版本
      apkmiaoshu: '',//apk描述

      arrapk: [],//apk列表

      fuliapp: [
        // { name: '福利APP', img: 'https://desk-fd.zol-img.com.cn/t_s960x600c5/g5/M00/02/03/ChMkJlbKxo2IT63KACN7OztCegEAALHmwPZIQ0AI3tT786.jpg' },
        // { name: '福利APP', img: 'https://desk-fd.zol-img.com.cn/t_s960x600c5/g5/M00/02/03/ChMkJlbKxo2IT63KACN7OztCegEAALHmwPZIQ0AI3tT786.jpg' },
        // { name: '福利APP', img: 'https://desk-fd.zol-img.com.cn/t_s960x600c5/g5/M00/02/03/ChMkJlbKxo2IT63KACN7OztCegEAALHmwPZIQ0AI3tT786.jpg' },

        // { name: '福利APP', img: 'https://desk-fd.zol-img.com.cn/t_s960x600c5/g5/M00/02/03/ChMkJlbKxo2IT63KACN7OztCegEAALHmwPZIQ0AI3tT786.jpg' },




      ],//福利app列表

      obb: false,


      jishu: 0,
    };
  },

  components: {
    // Notice
  },

  created() {
    this.fungetConfig()
    this.funapk()

  },
  methods: {

    fungetConfig() {
      this.showzhe = true;
      getConfig().then((res) => {
        this.showzhe = false;
        if (res.code == 1) {
          res.data.forEach(e => {
            if (e.title == '应用名称') {
              this.apkname = e
            }
            if (e.title == '应用描述') {
              this.apkmiaoshu = e
            }
            if (e.title == '应用大小') {
              this.apksize = e
            }
            if (e.title == '应用版本') {
              this.apkversion = e
            }
            if (e.title == '应用图标') {
              this.apkimg = e
            }
            if (e.title == '应用下载链接') {
              this.apkurl = e
            }
          });
        }
      }).catch(() => {
        this.showzhe = false;
      })
    },
    funapk() {
      apk().then((res) => {
        console.log(1, res);
        this.fuliapp = res.data.data
        console.log(this.fuliapp);

      })
      apks().then((res) => {
        if (res.code == 1) {
          this.arrapk = res.data.data
          // for (let i = 0; i < 3; i++) {
          //   this.arrapk.push(this.arrapk[0])
          // }
          console.log(this.arrapk);

        }
      })
    },



    // 开启提示框
    funti(e) {
      console.log(1);

      this.tishi = e;
      this.showtishi = true;

      let a = setInterval(() => {
        this.showtishi = false;
        this.tishi = "";
        clearInterval(a);
      }, 2000);
    },


    dian(e) {
      window.location.href = e

    },
    dians(e) {
      console.log(e);
      if (e.type == 1) {
        window.location.href = e.url
      } else if (e.type == 2) {
        window.location.href = e.app_file
      }
    },

    //客服跳转
    tiao(e) {
      window.location.href = e
      // window.open(e);
    },
  },
};
</script>
<style scoped lang="scss">
.router-link {
  text-decoration: none !important;
}

@media screen and (min-width: 1200px) {

  .box {
    width: 800px;
    margin: 30px auto 50px;
    background-color: #ffffff;
    padding: 50px 120px;
    border-radius: 20px;
    box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.1);
    .shang {
      display: none;
    }

    .top {
      .icon {
        display: flex;

        .left {
          img {
            width: 96px;
            height: 96px;
            border-radius: 20px;
          }
        }

        .right {
          margin-left: 20px;
          display: flex;
          flex-direction: column;
          justify-content: space-around;

          .name {
            font-size: 24px;
            font-weight: bold;
            color: #333333;
          }

          .version {
            font-size: 16px;
            font-weight: normal;
            color: #7F949A;
          }

          .miaoshu {
            font-size: 14px;
            font-weight: normal;
            color: #00AAD5;
          }
        }
      }

      .btns {
        margin-top: 40px;
        display: flex;
        align-items: center;
        text-align: center;

        .ss {

          .top {
            font-size: 18px;
            color: #1F4E5A;
            font-weight: bold;
          }

          .bottom {
            font-size: 12px;
            color: #7F969D;
          }
        }

        .one {
          margin-right: 38px;
        }

        .two {
          padding: 0 32px;
        }

        .three {
          margin-left: 32px;
          width: 168px;
          height: 48px;
          text-align: center;
          line-height: 48px;
          background-color: #00a9d3;
          font-size: 18px;
          color: #FFFFFF;
          border-radius: 100px;
          cursor: pointer;
        }

        .leftxian {
          height: 28px;
          width: 1px;
          background-color: #a4c0c7;
        }

        .bottom {
          margin-top: 3px;
        }

      }
    }


    .zhong {
      margin-top: 40px;
      width: 800px;

      .top {
        font-size: 18px;
        font-weight: bold;
        color: #1A424C;
      }

      .bottom {


        width: 800px;

        // overflow: hidden;
        .sikuan {
          width: 790px;
        }

        .sikuans {
          width: 816px;

        }

        .haha {
          overflow: auto;
          display: flex;
          padding: 20px;
          margin-left: -20px;

          .item {
            cursor: pointer;
            flex: 0 0 188px;
            height: 154px;
            border-radius: 8px;
            background: #FFFFFF;
            box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.2);
            overflow: hidden;
            margin-right: 16px;

            img {
              width: 188px;
              height: 110px;
            }

            .txt {
              height: 44px;
              line-height: 44px;
              font-size: 14px;
              color: #333333;
              padding: 0 10px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
          }
        }

      }
    }

    .xian {
      width: 800px;
      height: 1px;
      background-color: #cccccc;
      margin-top: 20px;
    }




    .bottom {
      margin-top: 30px;

      .name {
        font-size: 18px;
        font-weight: bold;
        color: #1A424C;
        margin-bottom: 20px;
      }

      .lists {
        overflow: hidden;

        .item {
          margin-bottom: 32px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          cursor: pointer;

          .left {
            display: flex;

            .img {
              width: 56px;
              height: 56px;
              border-radius: 12px;
              overflow: hidden;

              img {
                width: 100%;
                height: 100%;
              }
            }

            .txt {
              margin-left: 16px;
              display: flex;
              flex-direction: column;
              justify-content: space-evenly;

              .names {
                font-size: 16px;
                color: #00aad5;

              }

              .data {
                font-size: 14px;
                color: #333333;
              }
            }

          }

          .right {
            font-size: 14px;
            color: #333333;
          }
        }
      }

      .gao {
        max-height: 350px;
      }

      .gaos {
        height: auto;
      }
    }

    .chakan {
      box-sizing: border-box;
      height: 48px;
      width: 800px;
      text-align: center;
      line-height: 48px;
      color: #00aad5;
      border: 1px solid #00aad5;
      border-radius: 4px;
      cursor: pointer;
      font-size: 14px;
    }

  }



  // 提示
  .tishi {
    cursor: default;
    position: fixed;
    left: 50%;
    top: -10vh;
    transform: translate(-50%, -50%);
    font-family: Source Han Sans;
    font-size: 20px;
    z-index: 999;
    color: #ffffff;
    background-color: rgba(0, 0, 0, 0.8);
    padding: 20px 40px;
    // min-height: 54px;
    // line-height: 54px;
    border-radius: 3rem;
    transition: all 0.2s ease-in-out;
    text-align: center;
  }

  .tishis {
    top: 50vh;
  }

  //遮罩层
  .zhe {
    background-color: rgba(0, 0, 0, 0.7);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    text-align: center;

    img {
      position: absolute;
      width: 100px;
      top: 50vh;
      left: 50vw;
      margin-top: -50px;
      margin-left: -50px;
      transform-origin: 50px 50px;
      animation: slidein 2s linear infinite;
    }

    @keyframes slidein {
      0% {
        transform: rotate(0deg);
      }

      50% {
        transform: rotate(180deg);
      }

      100% {
        transform: rotate(360deg);
      }
    }
  }
}

@media screen and (max-width: 1200px) {

 




  .box {
    padding: 40rem 16rem;
    overflow: hidden;
    box-sizing: border-box;



    .xia {
      display: none;
    }

    .top {
      .icon {
        display: flex;

        .left {
          img {
            width: 84rem;
            height: 84rem;
            border-radius: 20rem;
          }
        }

        .right {
          margin-left: 16rem;
          display: flex;
          flex-direction: column;
          justify-content: space-around;

          .name {
            font-size: 20rem;
            font-weight: bold;
            color: #333333;
          }

          .version {
            font-size: 14rem;
            font-weight: normal;
            color: #7F949A;
          }

          .miaoshu {
            font-size: 12rem;
            font-weight: normal;
            color: #00AAD5;
          }
        }
      }

      .btns {
        margin-top: 20rem;
        display: flex;
        align-items: center;
        text-align: center;

        .ss {

          .top {
            font-size: 14rem;
            color: #1F4E5A;
            font-weight: bold;
          }

          .bottom {
            margin-top: 0rem;
            font-size: 12rem;
            color: #7F969D;
          }
        }

        .one {
          margin-right: 25rem;
          padding-left: 8rem;
        }

        .two {
          padding: 0 23rem;
        }

        .three {
          margin-left: 30rem;
          width: 152rem;
          height: 44rem;
          text-align: center;
          line-height: 44rem;
          background-color: #00a9d3;
          font-size: 16rem;
          color: #FFFFFF;
          border-radius: 150rem;
          cursor: pointer;
        }

        .leftxian {
          height: 28rem;
          width: 1px;
          background-color: #a4c0c7;
        }

      }
    }


    .zhong {
      margin-top: 24rem;
      width: 100%;

      .top {
        font-size: 16rem;
        font-weight: bold;
        color: #1A424C;
      }

      .bottom {
        margin-top: 11rem;
        width: 343rem;

        .sikuan {
          width: 343rem;
        }

        .sikuans {
          width: 353rem;

        }

        .haha {
          overflow: auto;
          display: flex;
          padding: 8rem 5rem 15rem 5rem;
          margin-left: -5rem;

          .item {
            cursor: pointer;
            flex: 0 0 78rem;
            height: 88rem;
            border-radius: 4rem;
            background: #FFFFFF;
            box-shadow: 0px 2rem 6rem 0px rgba(0, 0, 0, 0.2);
            overflow: hidden;
            margin-right: 10rem;

            img {
              width: 78rem;
              height: 60rem;
            }

            .txt {
              height: 28rem;
              line-height: 28rem;
              font-size: 12rem;
              color: #333333;
              padding: 0 10rem;
              text-align: center;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
          }
        }

      }
    }

    .xian {
      width: 100%;
      height: 1px;
      background-color: #cccccc;
      margin-top: 25rem;
    }




    .bottom {
      margin-top: 20rem;

      .name {
        font-size: 16rem;
        font-weight: bold;
        color: #1A424C;
        margin-bottom: 20rem;
      }

      .lists {
        overflow: hidden;

        .item {
          margin-bottom: 24rem;
          display: flex;
          justify-content: space-between;
          align-items: center;
          cursor: pointer;

          .left {
            display: flex;

            .img {
              width: 44rem;
              height: 44rem;
              border-radius: 10rem;
              overflow: hidden;

              img {
                width: 100%;
                height: 100%;
              }
            }

            .txt {
              margin-left: 12rem;
              display: flex;
              flex-direction: column;
              justify-content: space-evenly;

              .names {
                font-size: 13rem;
                color: #00aad5;

              }

              .data {
                font-size: 12rem;
                color: #333333;
              }
            }

          }

          .right {
            font-size: 12rem;
            color: #333333;
          }
        }
      }

      .gao {
        max-height: 270rem;
      }

      .gaos {
        height: auto;
      }
    }

    .chakan {
      box-sizing: border-box;
      height: 40rem;
      width: 343rem;
      text-align: center;
      line-height: 40rem;
      color: #00aad5;
      border: 1px solid #00aad5;
      border-radius: 4rem;
      cursor: pointer;
      font-size: 14rem;
    }

  }










  // 提示
  .tishi {
    cursor: default;
    position: fixed;
    left: 50%;
    top: -10vh;
    transform: translate(-50%, -50%);
    font-family: Source Han Sans;
    font-size: 18rem;
    z-index: 999;
    color: #ffffff;
    background-color: rgba(0, 0, 0, 0.8);
    padding: 15rem;
    border-radius: 5rem;
    transition: all 0.3s ease-in-out;
    width: 200rem;
    text-align: center;
  }

  .tishis {
    // top: 160rem;
    top: 50vh;
  }

  //遮罩层
  .zhe {
    background-color: rgba(0, 0, 0, 0.7);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    text-align: center;

    img {
      position: absolute;
      width: 100rem;
      top: 50vh;
      left: 50vw;
      margin-top: -50rem;
      margin-left: -50rem;
      transform-origin: 50rem 50rem;
      animation: slidein 2s linear infinite;
    }

    @keyframes slidein {
      0% {
        transform: rotate(0deg);
      }

      50% {
        transform: rotate(180deg);
      }

      100% {
        transform: rotate(360deg);
      }
    }
  }

  div {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
  }

}</style>